<template>
  <Table
    class="competitions-table"
    :columns="columns"
    :rows="competitions"
    :loading="competitionsLoading"
    :no-data-message="'No competitions found'"
  >
    <template #cell="{ column, row }">
      <CompetitionsSubscriptionTableCell
        :column-key="column.key"
        :competition="row"
      />
    </template>
  </Table>
</template>

<script>
import { filter as filterArray } from 'lodash';
import {
  computed, ref, watch, onUnmounted,
} from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import Table from '@/components/common/Table';
import CompetitionsSubscriptionTableCell from './CompetitionsSubscriptionTableCell';
import { createColumns } from './competitions-subscription-helper';

export default {
  components: {
    Table,
    CompetitionsSubscriptionTableCell,
  },
  setup() {
    const store = useStore();
    const columns = ref(filterArray(createColumns(), { visible: true }));

    const route = useRoute();
    const routeName = computed(() => route.name);

    const competitions = computed(() => store.getters.sidebarCompetitions);
    const competitionsLoading = computed(() => store.getters.sidebarLoading);

    onUnmounted(() => {
      store.dispatch('updateSearch', '');
      store.dispatch('updateCompetitionsSubscriptionsFilters', { type: 'clear' });
    });

    watch(() => store.getters.search, (newValues, oldValues) => {
      if (newValues !== oldValues) store.dispatch('loadSidebar');
    });

    watch(() => store.getters.rowsPerPage, (newValues, oldValues) => {
      if (newValues !== oldValues) store.dispatch('loadSidebar');
    });

    watch(() => store.getters.currentPage, (newValues, oldValues) => {
      if (newValues !== oldValues) store.dispatch('loadSidebar');
    });

    return {
      routeName,
      columns,
      competitionsLoading,
      competitions,
    };
  },
};
</script>

<style lang="scss">
.competitions-table {
  height: $eventTableHeight;

  &.filters-inactive {
    height: $eventTableHeightWithoutFilters;
  }

  .table-body .table-row {
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: $gray300;
    }
  }

  .table-cell-header.table-cell--actions {
    opacity: 1;
  }
}
</style>
