import formatDate, { DateTypes } from '@/utils/format-date';
import userRoles from '@/services/helpers/user-roles';
import env from '@/../config/env';

const { DPS_TRADER } = userRoles;

const createColumns = () => {
  const columns = {
    eventName: {
      label: 'Game',
      visible: true,
      order: 0,
      filterable: false,
      sortable: true,
      minWidth: 300,
    },
    region: {
      label: 'Region',
      visible: false,
      order: 1,
      filterable: false,
    },
    competition: {
      label: 'Competition',
      visible: true,
      order: 2,
      filterable: false,
      minWidth: 170,
    },
    sportTemplate: {
      label: 'Template',
      visible: false,
      order: 3,
      filterable: false,
    },
    startsAt: {
      label: 'Date & Time',
      visible: true,
      order: 4,
      inputType: 'date',
      defaultValue: () => formatDate(new Date(), DateTypes.ISO_DATE),
      sortable: true,
      minWidth: 145,
    },
    matchState: {
      label: 'Status',
      visible: true,
      order: 5,
      minWidth: 90,
      inputType: 'select',
      filterable: true,
      inputArgs: [
        {
          value: 'N_A',
          label: 'N/A',
        },
        {
          value: 'PREMATCH',
          label: 'Pregame',
        },
        {
          value: 'LIVE',
          label: 'In progress',
        },
        {
          value: 'FINISHED',
          label: 'Finished',
        },
        {
          value: 'CANCELED',
          label: 'Canceled',
        },
      ],
      defaultValue: () => 'N_A',
      sortable: true,
    },
    price: {
      label: 'Pricing',
      visible: true,
      order: 6,
      filterable: true,
      inputType: 'select',
      selectionType: 'single',
      inputArgs: [
        {
          value: 'none',
          label: 'Has no pricing',
        },
        {
          value: 'internal',
          label: 'Has pricing',
        },
      ],
      defaultValue: () => 'internal',
      unavailableForRoles: [DPS_TRADER],
      minWidth: 80,
    },
    approved: {
      label: 'Approved',
      visible: true,
      order: 7,
      filterable: false,
      minWidth: 100,
    },
    mapped: {
      label: 'Mapping',
      visible: true,
      order: 8,
      filterable: true,
      inputType: 'select',
      selectionType: 'single',
      inputArgs: [
        {
          value: 'no',
          label: 'Not mapped',
        },
        {
          value: 'yes',
          label: 'Mapped',
        },
      ],
      defaultValue: () => 'yes',
    },
    booking: {
      label: 'Booking',
      visible: true,
      order: 9,
      filterable: true,
      minWidth: 100,
      inputType: 'select',
      selectionType: 'single',
      inputArgs: [
        {
          value: 'no',
          label: 'Not booked',
        },
        {
          value: 'yes',
          label: 'Booked',
        },
      ],
      defaultValue: () => true,
    },
    highlight: {
      label: 'Highlight',
      visible: false,
      order: 10,
      filterable: false,
    },
    features: {
      label: 'Features',
      visible: true,
      untoggleable: true,
      order: 11,
      filterable: false,
    },
    actions: {
      label: '',
      visible: true,
      untoggleable: true,
      order: 13,
      filterable: false,
      nonResizable: true,
      unavailableForRoles: [DPS_TRADER],
      minWidth: 270,
    },
    suspended: {
      label: 'Suspended',
      visible: false,
      filterable: true,
      inputType: 'select',
      selectionType: 'single',
      inputArgs: [
        {
          value: 'no',
          label: 'Not suspended',
        },
        {
          value: 'yes',
          label: 'Suspended',
        },
      ],
    },
  };

  if (env.replay) {
    columns.eventType = {
      label: 'Replay',
      visible: false,
      untoggleable: true,
      order: 12,
      filterable: true,
      inputType: 'select',
      selectionType: 'single',
      inputArgs: [
        {
          value: 'REPLAY',
          label: 'Replay events',
        },
        {
          value: 'ORIGINAL',
          label: 'Current events',
        },
      ],
    };
  }

  return columns;
};

export default {
  createColumns,
};
