<template>
  <div class="competition-subscriptions-filters">
    <!-- subscription filter -->
    <div
      class="competition-subscriptions-filter-wrapper"
      @click="() => { subscriptionsFilterToggled = !subscriptionsFilterToggled }"
      ref="subscriptionsFilterRef"
    >
      <div class="selected-filter-value">
        <span>Subscription{{ selectedCompetitionSubscriptionFilter ? `: ${selectedCompetitionSubscriptionFilter.label}` : '' }}</span>
        <Icon
          class="filter-icon"
          :name="'chevron-selector'"
        />
      </div>
      <div
        class="competition-subscriptions-filter-dropdown"
        v-if="subscriptionsFilterToggled"
      >
        <div
          class="competition-subscriptions-filter-option"
          v-for="subscription in subscriptions"
          :key="subscription.id"
          @click="updateSubscriptionFilter(subscription)"
        >
          <Checkbox
            :round="true"
            :model-value="selectedCompetitionSubscriptionFilter?.id === subscription.id"
          />
          {{ subscription.label }}
        </div>
      </div>
    </div>
    <!-- mapping filter -->
    <div
      class="competition-subscriptions-filter-wrapper"
      @click="() => { mappingsFilterToggled = !mappingsFilterToggled }"
      ref="mappingsFilterRef"
    >
      <div class="selected-filter-value">
        <span>Mapping{{ selectedCompetitionMappingFilter ? `: ${selectedCompetitionMappingFilter.label}` : '' }}</span>
        <Icon
          class="filter-icon"
          :name="'chevron-selector'"
        />
      </div>
      <div
        class="competition-subscriptions-filter-dropdown"
        v-if="mappingsFilterToggled"
      >
        <div
          class="competition-subscriptions-filter-option"
          v-for="mapping in mappings"
          :key="mapping.id"
          @click="updateMappingFilter(mapping)"
        >
          <Checkbox
            :round="true"
            :model-value="selectedCompetitionMappingFilter?.id === mapping.id"
          />
          {{ mapping.label }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { onClickOutside } from '@vueuse/core';
import Checkbox from '@/components/common/Checkbox';
import Icon from '@/components/common/Icon';

export default {
  components: {
    Checkbox,
    Icon,
  },
  setup() {
    const store = useStore();
    const subscriptions = ref([
      {
        id: 1,
        label: 'Subscribed',
      },
      {
        id: 2,
        label: 'Not subscribed',
      },
    ]);

    const mappings = ref([
      {
        id: 1,
        label: 'Mapped',
      },
      {
        id: 2,
        label: 'Not mapped',
      },
    ]);

    const selectedCompetitionSubscriptionFilter = computed(() => store.getters.selectedCompetitionSubscriptionFilter);
    const selectedCompetitionMappingFilter = computed(() => store.getters.selectedCompetitionMappingFilter);

    const subscriptionsFilterRef = ref(false);
    const subscriptionsFilterToggled = ref(false);
    const mappingsFilterRef = ref(false);
    const mappingsFilterToggled = ref(false);

    const updateSubscriptionFilter = (data) => {
      const payload = data.id === selectedCompetitionSubscriptionFilter.value?.id ? null : data;
      store.dispatch('updateCompetitionsSubscriptionsFilters', { type: 'subscription', data: payload });
    };

    const updateMappingFilter = (data) => {
      const payload = data.id === selectedCompetitionMappingFilter.value?.id ? null : data;
      store.dispatch('updateCompetitionsSubscriptionsFilters', { type: 'mapping', data: payload });
    };

    onClickOutside(subscriptionsFilterRef, () => {
      subscriptionsFilterToggled.value = false;
    });

    onClickOutside(mappingsFilterRef, () => {
      mappingsFilterToggled.value = false;
    });

    return {
      subscriptions,
      subscriptionsFilterToggled,
      subscriptionsFilterRef,
      mappings,
      mappingsFilterToggled,
      mappingsFilterRef,
      selectedCompetitionSubscriptionFilter,
      selectedCompetitionMappingFilter,
      updateSubscriptionFilter,
      updateMappingFilter,
    };
  },
};
</script>

<style lang="scss">
.competition-subscriptions-filters {
    height: 56px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;

    .competition-subscriptions-filter-wrapper {
      width: 167px;
      height: 32px;
      border-radius: 4px;
      border: 1px solid #F0F0F0;
      cursor: pointer;
      position: relative;

      .selected-filter-value {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 8px;

        span {
          max-width: 85%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .icon {
          width: 16px;
          height: 16px;
        }
      }

      .competition-subscriptions-filter-dropdown {
        width: 180px;
        min-height: 32px;
        position: absolute;
        top: 33px;
        left: 0;
        border-radius: 4px;
        background-color: #fff;
        border: 1px solid #F0F0F0;
        box-shadow: 0px 2px 4px 0px #19141414;
        z-index: 999;

        .competition-subscriptions-filter-option {
          height: 32px;
          width: 100%;
          cursor: pointer;
          padding:  0 8px;
          display: flex;
          align-items: center;

          &:hover {
            background-color: rgba(0,0,0, 0.03);
          }

          .radio-input, .checkbox {
            margin-right: 4px;
          }

          span {
            max-width: 90%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }

    }
  }
</style>
