export const createColumns = () => ({
  competitionName: {
    key: 'competitionName',
    label: 'Competition',
    visible: true,
    order: 0,
    filterable: false,
    sortable: false,
    minWidth: 360,
  },
  sportName: {
    key: 'sportName',
    label: 'Sport',
    visible: true,
    order: 1,
    filterable: false,
    minWidth: 160,
    maxWidth: 160,
  },
  region: {
    key: 'regionName',
    label: 'Region',
    visible: true,
    order: 2,
    filterable: false,
    minWidth: 170,
  },
  mapped: {
    key: 'mapping',
    label: 'Mapping',
    visible: true,
    order: 8,
    filterable: true,
    minWidth: 160,
    maxWidth: 160,
  },
  features: {
    key: 'features',
    label: 'Features',
    visible: true,
    untoggleable: true,
    order: 11,
    filterable: false,
  },
  actions: {
    key: 'actions',
    label: '',
    visible: true,
    untoggleable: true,
    order: 14,
    filterable: false,
    nonResizable: true,
    minWidth: 270,
  },
});
